import React, { lazy, Suspense } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import helper from "../utils/helper";

const LoginPage = lazy(() => import("../pages/LoginPage"));
const ForgotPage = lazy(() => import("../pages/ForgotPage"));
const ResetPage = lazy(() => import("../pages/ResetPage"));

const DashboardPage = lazy(() => import("../pages/DashboardPage"));
const BlankNotAccess = lazy(() => import("../components/Blank/BlankNotAccess"));
const MasterPage = lazy(() => import("../pages/MasterPage"));
const MeetAddPage = lazy(() => import("../pages/MeetAddPage"));
const MeetDetailPage = lazy(() => import("../pages/MeetDetailPage"));

const PageLoader = () => (
  <div id="loader">
    <div className="loadingio-spinner-rolling-3kjtqyynjid">
      <div className="ldio-ucwibazh2i9">
        <div></div>
      </div>
    </div>
  </div>
);
const PublicRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const PrivateRoute = ({ title, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function Router(props) {
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <PublicRoute path="/login">
          <LoginPage />
        </PublicRoute>
        <PublicRoute path="/forgot">
          <ForgotPage />
        </PublicRoute>
        <Route
          exact
          path="/reset/:token?"
          render={(props) =>
            helper.isAuthenticated() ? (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: props.location },
                }}
              />
            ) : props.match.params.token ? (
              <ResetPage token={props.match.params.token} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
        <PrivateRoute path="/dashboard">
          <DashboardPage />
        </PrivateRoute>

        <PrivateRoute path="/meet-new">
          <MeetAddPage />
        </PrivateRoute>

        <Route
          exact
          path="/meet/detail/:room?"
          render={(props) =>
            !helper.isAuthenticated() ? (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            ) : props.match.params.room ? (
              <MeetDetailPage room={props.match.params.room} />
            ) : (
              <Redirect
                to={{
                  pathname: "/meet",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
        <PrivateRoute path={"*"}>
          {helper.isAuthenticated() ? <MasterPage /> : <BlankNotAccess />}
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
}
export default withRouter(Router);
