/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import CryptoJS from "crypto-js";
export default {
  decrypt(str) {
    try {
      const dec = CryptoJS.AES.decrypt(
        str,
        CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_KEY),
        {
          iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_KEY), // parse the IV
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC,
        }
      );
      return dec.toString(CryptoJS.enc.Utf8);
    } catch {
      return false;
    }
  },
  encrypt(str) {
    try {
      const enc = CryptoJS.AES.encrypt(
        str?.toString(),
        CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_KEY),
        {
          iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_KEY), // parse the IV
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC,
        }
      );
      return enc.toString();
    } catch {
      return false;
    }
  },
  isAuthenticated() {
    if (localStorage.getItem("db")) {
      const dirtyStorage = JSON.parse(this.decrypt(localStorage.getItem("db")));
      if (dirtyStorage?.token) {
        return dirtyStorage;
      }
    }
    return false;
  },
  getSession() {
    if (localStorage.getItem("session")) {
      const dirtyStorage = JSON.parse(
        this.decrypt(localStorage.getItem("session"))
      );

      return dirtyStorage;
    }
    return false;
  },
  setProfile(data, value) {
    const dirtyJson = this.getProfile();
    dirtyJson[data] = value;
    localStorage.setItem("profile", this.encrypt(JSON.stringify(dirtyJson)));
  },
  getProfile() {
    if (localStorage.getItem("profile")) {
      const dirtyStorage = JSON.parse(
        this.decrypt(localStorage.getItem("profile"))
      );

      return dirtyStorage;
    }
    return null;
  },
  truncString(str, max, add) {
    add = add || "...";
    return typeof str === "string" && str.length > max
      ? str.substring(0, max) + add
      : str;
  },
  formatDate(date) {
    var d = date,
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },

  convertDateTime(date) {
    const d = new Date(date);
    let day = "";
    if (d.getDate() > 9) {
      day = d.getDate();
    } else {
      day = "0" + d.getDate();
    }
    const month = d.getMonth() + 1;
    const start_date = d.getFullYear() + "-" + month + "-" + day;
    return start_date;
  },

  formatRupiah(angka, prefix) {
    var number_string = angka
        ? angka
            .toString()
            .replace(/[^,\d]/g, "")
            .toString()
        : "",
      split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      let separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
  },
  formatN(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  },
  /* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
  isHTML(str) {
    var a = document.createElement("div");
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType === 1) return true;
    }

    return false;
  },
  autoFormatNPWP(NPWPString) {
    try {
      var cleaned = ("" + NPWPString).replace(/\D/g, "");
      var match = cleaned.match(
        /(\d{0,2})?(\d{0,3})?(\d{0,3})?(\d{0,1})?(\d{0,3})?(\d{0,3})$/
      );
      return [
        match[1],
        match[2] ? "." : "",
        match[2],
        match[3] ? "." : "",
        match[3],
        match[4] ? "." : "",
        match[4],
        match[5] ? "-" : "",
        match[5],
        match[6] ? "." : "",
        match[6],
      ].join("");
    } catch (err) {
      return "";
    }
  },
  terbilang(nilai) {
    nilai = Math.abs(nilai);
    var simpanNilaiBagi = 0;
    var huruf = [
      "",
      "Satu",
      "Dua",
      "Tiga",
      "Empat",
      "Lima",
      "Enam",
      "Tujuh",
      "Delapan",
      "Sembilan",
      "Sepuluh",
      "Sebelas",
    ];
    var temp = "";

    if (nilai < 12) {
      temp = " " + huruf[nilai];
    } else if (nilai < 20) {
      temp = this.terbilang(nilai - 10) + " Belas";
    } else if (nilai < 100) {
      simpanNilaiBagi = Math.floor(nilai / 10);
      temp =
        this.terbilang(simpanNilaiBagi) + " Puluh" + this.terbilang(nilai % 10);
    } else if (nilai < 200) {
      temp = " Seratus" + this.terbilang(nilai - 100);
    } else if (nilai < 1000) {
      simpanNilaiBagi = Math.floor(nilai / 100);
      temp =
        this.terbilang(simpanNilaiBagi) +
        " Ratus" +
        this.terbilang(nilai % 100);
    } else if (nilai < 2000) {
      temp = " Seribu" + this.terbilang(nilai - 1000);
    } else if (nilai < 1000000) {
      simpanNilaiBagi = Math.floor(nilai / 1000);
      temp =
        this.terbilang(simpanNilaiBagi) +
        " Ribu" +
        this.terbilang(nilai % 1000);
    } else if (nilai < 1000000000) {
      simpanNilaiBagi = Math.floor(nilai / 1000000);
      temp =
        this.terbilang(simpanNilaiBagi) +
        " Juta" +
        this.terbilang(nilai % 1000000);
    } else if (nilai < 1000000000000) {
      simpanNilaiBagi = Math.floor(nilai / 1000000000);
      temp =
        this.terbilang(simpanNilaiBagi) +
        " Miliar" +
        this.terbilang(nilai % 1000000000);
    } else if (nilai < 1000000000000000) {
      simpanNilaiBagi = Math.floor(nilai / 1000000000000);
      temp =
        this.terbilang(nilai / 1000000000000) +
        " Triliun" +
        this.terbilang(nilai % 1000000000000);
    }

    return temp;
  },
  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  },
};
