import Result from "antd/es/result";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
function BlankCacheComponent(props) {
  const { emptyCacheStorage, isLatestVersion } = props;
  React.useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLatestVersion]);
  return (
    <Result
      icon={
        <img
          src={`/logo.png`}
          alt="Logo OmniX Conference"
          style={{ width: 250 }}
        />
      }
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
      }}
      title={
        <div className="oxanium" style={{ fontWeight: "bold", fontSize: 14 }}>
          {" "}
          Browser verification before entering the website.
        </div>
      }
      subTitle={
        <div className="opensans" style={{ fontSize: 12 }}>
          {" "}
          The browser will automatically redirect to the main page once the
          process is complete.
        </div>
      }
      extra={[
        <LoadingOutlined
          key={"loading"}
          style={{
            fontSize: 24,
          }}
          spin
        />,
      ]}
    />
  );
}
export default BlankCacheComponent;
