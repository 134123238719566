import React, { useEffect, useState } from "react";
import Spin from "antd/es/spin";
import notification from "antd/es/notification";
import ConfigProvider from "antd/es/config-provider";
import Router from "./router";
import http from "../utils/http";

function Main(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  useEffect(() => {
    http.interceptors.request.use(
      (config) => {
        setOpenBackdrop(true);
        return config;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          notification.error({
            message: error.response
              ? typeof error.response.data?.message === "string"
                ? error.response.data?.message
                : error.response.data?.message
                ? error.response.data?.message?.map((v) => v.message)?.join(",")
                : "An error occurred in the application, please try again later."
              : "An error occurred in the application, please try again later.",
            placement: "bottomRight",
            duration: 5,
          });
        }
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        if (response?.config?.method !== "get") {
          if (response?.data?.message) {
            notification.success({
              message: response.data.message,
              placement: "bottomRight",
              duration: 5,
            });
          }
        }
        setOpenBackdrop(false);
        return response;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          notification.error({
            message: error.response
              ? typeof error.response.data?.message === "string"
                ? error.response.data?.message
                : error.response.data?.message
                ? error.response.data?.message?.map((v) => v.message)?.join(",")
                : "An error occurred in the application, please try again later."
              : "An error occurred in the application, please try again later.",
            placement: "bottomRight",
            duration: 5,
          });
        }
        return Promise.reject(error);
      }
    );

    if (!props.isLatestVersion) {
      props.emptyCacheStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3f3192",
          colorLink: "#3f3192",
          colorLinkHover: "#3f3192",
        },
      }}
    >
      <Spin spinning={openBackdrop} tip="Please wait..." className="h-full">
        <Router />
      </Spin>
    </ConfigProvider>
  );
}

export default Main;
